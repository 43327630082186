<template>
  <v-form
    class="w-100"
    ref="clientForm"
    :key="forceUpdateKey"
    @submit.prevent="submitClientForm"
  >
    <v-row v-show="confirmationStep && isCompanyClient && !editingClient">
      <v-col
        cols="12"
        class="pb-0"
      >
        <div class="d-flex align-items-center">
          <v-btn
            text
            x-small
            depressed
            width="32px"
            height="32px"
            color="error"
            @click="confirmationStep = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <Warning
            message="Dane klienta różnią się od danych z GUS. Potwierdź dodanie klienta."
            hide-icon
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-show="!confirmationStep">
      <v-col
        cols="12"
        class="pt-0 d-flex align-end"
        v-if="!editingClient"
      >
        <div class="ml-auto">
          <v-radio-group
            row
            v-model="client.clientType"
            hide-details
          >
            <v-radio
              v-for="type in filters.clientTypes"
              :key="type"
              :value="type"
              :label="type"
            />
          </v-radio-group>
        </div>
      </v-col>
      <v-col
        v-if="isCompanyClient && !editingClient"
        cols="12"
        class="py-0 d-flex align-end"
      >
        <div class="ml-auto">
          <v-checkbox
            v-model="isEditingWithoutGUSFetch"
            label="Pomiń obowiązek wyszukania w GUS"
            hide-details
            class="mt-0 pt-0 pl-0 mr-4"
            @change="setBdoType"
          />
        </div>
      </v-col>
      <v-col
        v-if="isCompanyClient"
        cols="12"
        class="d-flex align-start"
      >
        <v-text-field
          outlined
          label="Nr NIP"
          v-model="nipQuery"
          :rules="isCompanyClient && !isEditingWithoutGUSFetch && !editingClient ? [value => rules.required(value, 'Musisz wyszukać klienta po NIP by móc go dodać'), rules.nipNumber] : []"
          :disabled="!isCompanyClient || !!editingClient"
          placeholder="Wpisz numer NIP"
          validate-on-blur
          v-mask="!isEditingWithoutGUSFetch ? '##########' : ''"
          @keyup.enter="fetchGusData"
        />
        <v-btn
          color="primary"
          class="ml-2"
          outlined
          icon
          :disabled="gusSearchDisabled || !isCompanyClient"
          :loading="isProcessing"
          @click="fetchGusData"
        >
          <v-icon color="primary">
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="client.name"
          outlined
          label="Nazwa klienta"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="[rules.required]"
          placeholder="Wpisz nazwę klienta"
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="client.patronId"
          :items="filters.patrons"
          :disabled="isCompanyClient && isInputDisabled"
          placeholder="Wybierz opiekuna klienta"
          label="Opiekun"
          item-text="fullName"
          item-value="id"
          append-icon="mdi-chevron-down"
          outlined
          dense
          clearable
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="client.middlemen"
          :items="middlemen"
          :disabled="isCompanyClient && isInputDisabled"
          multiple
          placeholder="Wybierz pośrednika klienta"
          label="Pośrednik"
          item-text="name"
          item-value="id"
          clearable
          append-icon="mdi-chevron-down"
          outlined
          dense
        >
          <template
            #selection="{ item, index }"
          >
            <MultiselectChoices
              :index="index"
              :label="item.name"
              :array-length="client.middlemen ? client.middlemen.length : 0"
              :visible-items="1"
              :max-length="30"
            />
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          outlined
          :items="bdoTypes"
          label="Logika BDO"
          placeholder="Wybierz logikę BDO"
          item-text="type"
          item-value="type"
          :disabled="isCompanyClient && isInputDisabled"
          v-model="client.bdoType"
          :rules="[rules.required]"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.bdoNumber"
          outlined
          label="Nr BDO"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="exemptFromBdo ? [] : [rules.required]"
          placeholder="Wpisz numer BDO"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.controlName"
          :disabled="isCompanyClient && isInputDisabled"
          outlined
          label="Dane przekazującego"
          :rules="isControlNameRequired ? [rules.required] : []"
          placeholder="Wpisz dane przekazującego"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model.number="client.purchaseLimit"
          outlined
          label="Limit kupiecki [zł]"
          type="number"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="[rules.money, rules.lowerThan(10000001)]"
          validate-on-blur
          placeholder="Wpisz wysokość limitu"
          @wheel="$event.target.blur()"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.phoneNumber"
          outlined
          label="Numer telefonu"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="[rules.phoneNumber]"
          v-mask="getFieldMask('phoneNumber')"
          placeholder="Wpisz numer telefonu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.email"
          outlined
          label="Email"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="[rules.email]"
          validate-on-blur
          placeholder="Wpisz adres email"
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="client.paymentType"
          :items="paymentTypes"
          :disabled="isCompanyClient && isInputDisabled"
          outlined
          label="Typ płatności"
          placeholder="Wybierz typ platności"
          item-text="type"
          item-value="type"
          :rules="[rules.required]"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.paymentDueDays"
          outlined
          label="Termin przelewu"
          :disabled="!client.paymentType"
          :rules="client.paymentType === 'Przelew' ? [rules.required] : []"
          validate-on-blur
          placeholder="Wpisz termin przelewu"
          @wheel="$event.target.blur()"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.contractNumber"
          outlined
          label="Numer umowy"
          :disabled="isCompanyClient && isInputDisabled"
          placeholder="Wpisz numer umowy"
        />
      </v-col>
      <v-col cols="6">
        <v-menu
          v-model="showContractDatePicker"
          v-bind="attributes.menu"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              :value="formatDate(client.contractDate)"
              label="Data umowy"
              placeholder="Wybierz datę umowy"
              :disabled="isCompanyClient && isInputDisabled"
              v-bind="{ ...attrs, ...attributes.textField }"
              v-on="on"
            >
              <template #prepend-inner>
                <Icon
                  name="date"
                  size="small"
                  class="mr-2"
                />
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="client.contractDate"
            v-bind="attributes.datePicker"
            @input="showContractDatePicker = false"
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        class="mt-4"
      >
        <v-textarea
          v-model="client.notes"
          outlined
          :rows="2"
          auto-grow
          :disabled="isCompanyClient && isInputDisabled"
          label="Notatka do klienta"
          placeholder="Wpisz notatkę do klienta"
        />
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <div class="d-flex justify-space-between">
          <div class="form__section-title d-flex align-center">
            Dane do faktury
            <v-btn
              icon
              x-small
              color="primary"
              class="ml-2 mt-1"
              title="Skopiuj dane do faktury"
              @click="copyInvoiceData"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="isCompanyClient"
      >
        <v-text-field
          v-model="client.invoiceBuyerName"
          outlined
          label="Nabywca"
          :disabled="isInputDisabled"
          :rules="[rules.required]"
          placeholder="Wpisz nazwę nabywcy"
        />
      </v-col>

      <v-col
        v-else
        cols="12"
      >
        <v-autocomplete
          outlined
          :items="settlementTypes"
          label="Typ dokumentu"
          placeholder="Wybierz typ dokumentu"
          v-model="client.settlementType"
          :disabled="settlementTypes && settlementTypes.length === 1"
          :rules="[rules.required]"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>

      <v-col cols="8">
        <v-text-field
          v-model="client.invoiceAddress"
          outlined
          label="Adres"
          validate-on-blur
          :rules="[isInvoiced ? rules.required : true]"
          :disabled="isCompanyClient && isInputDisabled"
          placeholder="Wpisz ulicę i nr lokalu"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="client.invoicePhoneNumber"
          outlined
          label="Numer telefonu"
          :rules="[rules.phoneNumber]"
          placeholder="Wpisz numer telefonu"
          :disabled="isCompanyClient && isInputDisabled"
          v-mask="getFieldMask('phoneNumber')"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pb-0"
      >
        <v-text-field
          v-model="client.invoicePostalCode"
          outlined
          label="Kod pocztowy"
          :rules="isEditingWithoutGUSFetch ? [rules.required] : [isInvoiced ? rules.required : true, rules.postCode]"
          :disabled="isCompanyClient && isInputDisabled"
          validate-on-blur
          :placeholder="!isEditingWithoutGUSFetch ? 'xx-xxx' : 'Wpisz kod pocztowy'"
          v-mask="!isEditingWithoutGUSFetch ? getFieldMask('postCode') : ''"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="pb-0"
      >
        <v-text-field
          v-model="client.invoiceCity"
          outlined
          label="Miejscowość"
          :disabled="isCompanyClient && isInputDisabled"
          :rules="isInvoiced ? [rules.required] : []"
          placeholder="Wpisz nazwę miejscowości"
        />
      </v-col>

      <v-col
        cols="12"
        class="pb-0"
      >
        <v-checkbox
          v-model="client.signatureRequired"
          hide-details
          class="mt-0"
          :disabled="isCompanyClient && isInputDisabled"
          label="Podpis wymagany"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-checkbox
          v-model="client.isMiddleman"
          hide-details
          class="mt-0"
          :disabled="(isCompanyClient && !isClientFound) || isAddingClientToMiddlemanMode"
          label="Czy pośrednik?"
        />
      </v-col>
      <v-col
        v-if="actions"
        class="py-0"
      >
        <v-btn
          text
          small
          @click="$emit('close')"
        >
          Anuluj
        </v-btn>

        <v-btn
          color="primary"
          type="submit"
          text
          small
        >
          Zapisz klienta
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '../../../utils/validators'
import {
  parseAsBasicUnit,
  getFieldMask,
  formatChangedPhoneNumber,
  dateStringFormat,
} from '../../../utils'
import { mapState, mapActions } from 'vuex'
import { Client } from '../../../models'
import debounce from 'lodash/debounce'
import attributes from '../../../const/datePickerAttrributes'
import MultiselectChoices from '../../Elements/MultiselectChoices.vue'

export default {
  components: {
    MultiselectChoices
  },
  props: {
    actions: {
      type: Boolean,
      default: false,
    },
    editingClient: {
      type: Object,
      required: false,
    },
    newClientMiddleman: {
      type: Object,
      required: false
    },
    isAddingClientToMiddlemanMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      attributes,
      rules,
      client: new Client(),
      initialClientFromGUS: new Client(),
      isClientFound: false,
      isClientFetchedFromGUS: false,
      isEditingWithoutGUSFetch: false,
      nipQuery: '',
      showContractDatePicker: false,
      forceUpdateKey: 0,
      confirmationStep: false,
    }
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters,
      clientFilters: state => state.tables.clients.filters,
      users: state => state.users.items,
      paymentTypes: state => state.core.filters.paymentTypes,
      middlemen: state => state.middlemen.items,
      isProcessing: state => state.client.isProcessing,
    }),
    isInputDisabled() {
      return this.isCompanyClient ? !this.isClientFound && !this.isEditingWithoutGUSFetch : false
    },
    settlementTypes() {
      return this.isCompanyClient ? this.filters.companySettlementTypes : this.filters.individualSettlementTypes
    },
    isInvoiced() {
      return this.client.settlementType?.includes('Faktura')
    },
    bdoTypes() {
      return this.isCompanyClient
        ? this.filters.clientBdoTypes.filter(type => type.company)
        : this.filters.clientBdoTypes.filter(type => !type.company)
    },
    gusSearchDisabled() {
      const invoiceNip = this.nipQuery
      return invoiceNip ? invoiceNip.toString().length !== 10 : !invoiceNip
    },
    isCompanyClient() {
      return this.client.clientType === 'Firma'
    },
    isControlNameRequired() {
      return ['Oświadczenie', 'Zwolniony'].includes(this.client.bdoType)
    },
    exemptFromBdo() {
      return this.filters.clientBdoTypes
        ?.filter(type => type.exempt)
        ?.map(type => type.type)
        ?.includes(this.client.bdoType)
    },
    clientDiffersFromGUS() {
      if (this.isEditingWithoutGUSFetch || this.editingClient) return false
      const { invoiceNip, name, invoicePostalCode, invoiceCity, invoiceAddress } = this.client
      const { invoiceNip: initialInvoiceNip, name: initialName, invoicePostalCode: initialInvoicePostalCode, invoiceCity: initialInvoiceCity, invoiceAddress: initialInvoiceAddress } = this.initialClientFromGUS
      return invoiceNip !== initialInvoiceNip || name !== initialName || invoicePostalCode !== initialInvoicePostalCode || invoiceCity !== initialInvoiceCity || invoiceAddress !== initialInvoiceAddress
    } // check if client differs from initial client fetched from GUS, BE no longer returns error when client differs from GUS, so we need to check it on FE
  },
  created() {
    if (this.editingClient) {
      this.isClientFound = true
      const baseClient = { ...this.editingClient }
      baseClient.purchaseLimit = baseClient.purchaseLimit / 100
      this.client = new Client(baseClient)
      this.nipQuery = this.editingClient.invoiceNip
    }
    const clientType = this.clientFilters.find(
      filter => filter.name === 'clientType'
    )?.filterBy
    if (this.$route.name === 'clients' && clientType) {
      this.client.clientType = clientType
    }
  },
  watch: {
    'client.clientType'(type) {
      this.$refs.clientForm.resetValidation()
      this.client.settlementType = this.settlementTypes[0]
      const bdoTypes = this.bdoTypes.map(type => type.type)
      if (!bdoTypes.includes(this.client.bdoType)) this.client.bdoType = null
      if (type === 'Firma' && !this.client.patronId) {
        this.$set(this.client, 'patronId', this.filters.patrons.find(patron => patron.fullName === 'Biuro Biuro')?.id)
      }
    },
    'client.phoneNumber'(value) {
      if (value && !value.includes('-') && value.length === 9) {
        this.$nextTick(() => {
          this.client.phoneNumber = String(this.formatChangedPhoneNumber(value))
        }) // v-mask doesn't work when phone number has length of 9 digits. (format #########), problem is visible especially when you overwrite/overpaste phone number with new value.
      }
    },
    'client.paymentType'(value) {
      this.$refs.clientForm.resetValidation()
      if (value === 'Przelew') {
        this.client.paymentDueDays = 7
      }
    },
    bdoTypes: {
      handler(value) {
        if (value.length === 1) {
          this.client.bdoType = value[0].type
        }
      },
      immediate: true
    }, // if there is only one bdo type available, set it as default - it's case when we have indiviudal client and bdo logic "indiviudal"
    newClientMiddleman(value) {
      if (value) {
        this.client.middlemen = [value.id]
        this.forceUpdateKey++
      }
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      fetchGusData: debounce(
        function(dispatch) {
          dispatch('client/fetchGusData', this.nipQuery).then(data => {
            this.client.invoiceNip = this.nipQuery
            this.isClientFound = true
            this.isClientFetchedFromGUS = true
            const {
              invoiceAddress,
              invoiceCity,
              invoicePostalCode,
              phoneNumber,
              name,
              bdoNumber,
              message,
            } = data
            this.client.invoiceAddress = invoiceAddress
            this.client.invoiceBuyerName = name
            this.client.name = name
            this.client.invoiceCity = invoiceCity
            this.client.invoicePostalCode = invoicePostalCode
            this.client.invoicePhoneNumber = String(this.formatChangedPhoneNumber(phoneNumber || ''))
            this.initialClientFromGUS = { ...this.client } // save initial client from GUS to compare it with client edited by user
            if (message) {
              this.showSnackbar({ message: [message], type: 'error' })
            } else {
              this.client.bdoNumber = bdoNumber
            }
          }).catch(() => {
            this.nipQuery = this.client.invoiceNip
          })
        },
        3000,
        { leading: true }
      ),
    }),
    getFieldMask,
    formatChangedPhoneNumber,
    formatDate(date) {
      return date ? dateStringFormat(date) : ''
    },
    copyInvoiceData() {
      this.client.invoiceBuyerName = this.client.name
      this.client.invoicePhoneNumber = this.client.phoneNumber
    },
    setBdoType(val) {
      if (val) this.client.bdoType = 'Zwolniony' // on change of isEditingWithoutGUSFetch, set bdoType to "Zwolniony" as default value for this field
    },
    submitClientForm() {
      if (this.$refs.clientForm.validate()) {
        if (this.clientDiffersFromGUS && !this.confirmationStep && this.isCompanyClient) {
          this.confirmationStep = true
          return
        }
        const { middlemen, ...clientData } = this.client

        const client = {
          ...clientData,
          invoicePostalCode: this.client?.invoicePostalCode || null,
          invoiceCity: this.client?.invoiceCity || null,
          invoiceAddress: this.client?.invoiceAddress || null,
          middlemanIds: this.client.middlemen,
          invoiceNip: this.client.invoiceNip || this.nipQuery,
          purchaseLimit: parseAsBasicUnit(this.client.purchaseLimit),
          invoiceBuyerName: this.client.invoiceBuyerName || this.client.name,
        // name field is the default value for invoiceBuyerName
        }

        if (this.client.clientType !== 'Firma') {
          delete client.invoiceNip
        }

        this.$emit('submitClientForm', client)
        this.resetFormData()
      }
    },
    resetFormData() {
      this.client = new Client()
      this.initialClientFromGUS = new Client()
      this.nipQuery = ''
      this.isClientFound = false
      this.isClientFetchedFromGUS = false
      this.isEditingWithoutGUSFetch = false
      this.showContractDatePicker = false
      this.confirmationStep = false
      this.forceUpdateKey++
    }
  },
}
</script>
